@import "../../../assets/styles/colors";

.subscribeContainer,
.bundleContainer {
  text-align: center;
}

.subscribeContainer {
  margin-top: 3rem;
}

.bundleContainer {
  margin-top: 1.5rem;
}

.bundleContainer.extraMarginTop {
  margin-top: 3rem;
}

.blankContainer {
  margin-top: 1.5rem;
}

.subscribeButton,
.bundleButton {
  width: fit-content;
  font-size: 1rem;
  border-radius: 42px;
}

@media (min-width: 420px) {
  .subscribeButton,
  .bundleButton {
    font-size: 1rem;
  }
}

@media (min-width: 576px) {
  .subscribeButton,
  .bundleButton {
    font-size: 1.2rem;
    border-radius: 21px;
  }
}
