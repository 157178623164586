@import "../../../assets/styles/_colors.scss";

.badge {
  border-radius: 40px;
  padding: 2px 15px;
  margin-top: 8px;
  margin-left: 10px;
  color: $theme-terciary-color;
  font-weight: 600;
  border: 2px solid $theme-terciary-color;
  white-space: nowrap;
}

.badge.selected {
  color: $background-color;
  background-color: $theme-terciary-color;
}

button.badge:focus {
  outline: none;
  border: 2px solid $theme-primary-color;
}
