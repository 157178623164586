@import "../../assets/styles/_colors.scss";
@import "../../assets/styles/_sizes.scss";

.container {
  padding-top: $navbar-height + 50px;
}

.pageTitle {
  font-size: 26px;
}

.courseTitle {
  font-size: 23px;
  color: $theme-primary-color-faint-50;
}

.loadingContainer {
  margin-top: 80px;
  padding-bottom: 80px;
  text-align: center;
}

.loadingSpinner {
  font-size: 22px;
}

@media (min-width: 576px) {
  .pageTitle {
    font-size: 28px;
  }

  .courseTitle {
    font-size: 26px;
  }
}

@media (min-width: 768px) {
  .pageTitle {
    font-size: 34px;
  }

  .courseTitle {
    font-size: 28px;
  }
}

@media (min-width: 992px) {
  .pageTitle {
    font-size: 40px;
  }

  .courseTitle {
    font-size: 34px;
  }
}
