.instructions {
  font-size: 18px;
  margin-top: 50px;
  margin-bottom: 35px;
}

.downloadMaterialsBtn,
.emailInput {
  max-width: 380px;
  display: inline-block;
}

.downloadMaterialsBtn {
  width: 100%;
  border-radius: 25px;
  font-size: 1.25rem;
  margin-top: 15px;
  margin-bottom: 60px;
}

.downloadingMaterialsSpinner {
  width: 1.3rem;
  height: 1.3rem;
  font-size: 1rem;
}

@media (min-width: 576px) {
  .instructions {
    font-size: 22px;
  }
}

@media (min-width: 768px) {
  .instructions {
    font-size: 24px;
  }
}

@media (min-width: 992px) {
  .instructions {
    font-size: 26px;
  }
}
