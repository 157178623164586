@import "../../assets/styles/colors";
@import "../../assets/styles/sizes";

.pageTitle {
  font-size: 3rem;
}

.checkoutContainer {
  padding-top: $navbar-height;
  padding-bottom: 60px;
}

.informationIcon {
  font-size: 2rem;
}

.spotConfirmationText {
  font-size: 1.3rem;
  font-weight: 600;
}

.emailConfirmationText {
  font-size: 1.3rem;
  font-weight: 600;
}

.printThisPageText {
  font-size: 1.1rem;
}

.printThisPageText:hover {
  text-decoration: underline;
}
