@import "../../assets/styles/_colors.scss";
@import "../../assets/styles/_sizes.scss";

.container {
  padding-top: $navbar-height;
}

.buttonRow {
  display: flex;
  flex-direction: row-reverse;
  padding-bottom: 0.2rem;
}

.show {
  display: flex;
}

.hide {
  display: none;
}
