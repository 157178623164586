@import "../../../assets/styles/_colors.scss";
@import "../../../assets/styles/_sizes.scss";

.container {
  font-size: 1.5rem;
  padding-bottom: 75px;
}

.header {
  margin-bottom: 25px;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}
