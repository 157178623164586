@import "../../../assets/styles/colors";

.exclamationIcon {
  font-size: 2rem;
}

.instructionsText {
  font-size: 1.3rem;
  font-weight: 600;
}

.postCheckoutQuestionLabel {
  font-weight: 600;
}

button.submitAnswersBtn {
  width: 250px;
  margin-top: 1.75rem;
  font-size: 1.4rem;
  border-radius: 29px;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  text-decoration: none;
}

button.submitAnswersBtn > div {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 16px;
}

button.submitAnswersBtn:focus {
  padding-top: calc(0.4rem - 1px);
  padding-bottom: calc(0.4rem - 1px);
}
