@import '../../../../assets/styles/_colors.scss';
@import '../../../../assets/styles/_sizes.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $background-color;
  padding: 20px 40px;
  margin-bottom: 35px;
  border-radius: 8px;
}

.photo {
  width: 120px;
  height: 120px;
  margin-bottom: 15px;
}

.textContainer {
  flex: 1;
}

.headline {
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 5px;
}

.titleContainer {
  display: block;
  font-size: 20px;
}

.description {
  font-size: 18px;
  color: $theme-primary-color-faint;
  margin-bottom: 0;
}

@media (min-width: 576px) {
  .container {
    flex-direction: row;
  }
  .photo {
    margin-right: 35px;
    margin-bottom: auto;
  }
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}
