@import "../../../assets/styles/_colors.scss";
@import "../../../assets/styles/_sizes.scss";

.container {
  font-size: 1.5rem;
  padding-bottom: 55px;
  background-color: $theme-primary-color-faint-10;
}

.teachersBio h3 {
  font-size: 24px;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}
