@import '../../../assets/styles/_colors.scss';
@import '../../../assets/styles/_sizes.scss';

.container {
  padding-bottom: 85px;
  background-color: $background-color;
}

.header {
  margin-bottom: 55px;
}

.testimonialContainer {
  font-size: 20px;
  color: $theme-primary-color;
  padding-bottom: 25px;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}
