@import "../../../assets/styles/_colors.scss";

// Set border-collapse to `separate` so that rounded corners will work.
.table {
  border-collapse: separate;
  border-spacing: 0;
}

// Remove the default borders from the <thead> element.
.table > thead th {
  border-bottom: none;
  border-top: none;
}

////// TABLE BORDER //////

.table td {
  border: unset;
  border-right: 1px solid $theme-secondary-color;
  border-bottom: 1px solid $theme-secondary-color;
}

.table td:first-child {
  border-left: 1px solid $theme-secondary-color;
}
//////////////////////////

////// TABLE BORDER RADIUS //////
.table > thead th:first-child {
  border-top-left-radius: 4px;
}

.table > thead th:last-child {
  border-top-right-radius: 4px;
}

.table tr:last-child > td:first-child {
  border-bottom-left-radius: 4px;
}

.table tr:last-child > td:last-child {
  border-bottom-right-radius: 4px;
}
/////////////////////////////////

.table > thead {
  color: $background-color;
  background-color: $theme-secondary-color;
}

.table > tbody > tr {
  color: $theme-secondary-color;
  font-weight: 600;
}

a.tableLink {
  padding: 4px 12px 5px;
  border-radius: 4px;
  text-decoration: underline;
  display: inline-block;
  color: $theme-secondary-color;
  cursor: pointer;
}

a.tableLink:hover {
  color: $background-color;
  background-color: $theme-secondary-color;
}

a.tableLink:global(.disabled) {
  color: $theme-secondary-color;
  background-color: $background-color;
  text-decoration: none !important;
  cursor: default !important;
}

.emptyRowContainer {
  font-size: 1.5rem;
}

// Style the refund confirm modal
.confirmModal {
  position: absolute;
  // This is a weird one. It's difficult to relatively size a modal, and 500px matches the size well,
  // but if the screen doesn't have 500px of width to give it, 80% is a better size.
  width: calc(min(80vw, 500px));
  height: auto;
  min-height: auto;
  // There must be some style collision with the react-bootstrap modal styles,
  // because setting left and right to something greater than 50% or auto or other tricks weren't working,
  // so here we are with calc calls
  left: calc((100vw - min(80vw, 500px)) / 2);
}

.buttonRow {
  margin-top: 0.5rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.visible {
  display: initial;
}

.hidden {
  display: none;
}
//
#refundModalSubmitButton {
  width: 100%;
  margin-top: 0.25rem;
}
