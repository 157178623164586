@import "../../../assets/styles/colors";

// .paymentHeader {
// }

.copyIcon {
  font-size: 1rem;
  margin-bottom: 4px;
}

.lockIcon {
  font-size: 1.5rem;
  margin-bottom: 2px;
}

.stripeBadge {
  margin-top: 6px;
  height: 2rem;
}

button.placeOrderBtn {
  width: 250px;
  margin-top: 2.25rem;
  font-size: 1.4rem;
  border-radius: 29px;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  text-decoration: none;
}

.applyButton {
  margin-left: 0.1em;
  border-color: $theme-terciary-color;
  color: $theme-terciary-color;
}

.applyButton:hover {
  background-color: $theme-terciary-color;
  border-color: $theme-terciary-color;
}

button.placeOrderBtn > div {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 16px;
}

button.placeOrderBtn:focus {
  padding-top: calc(0.4rem - 1px);
  padding-bottom: calc(0.4rem - 1px);
}
