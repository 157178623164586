@import "../../../assets/styles/colors";

button.checkoutBtn {
  width: 100%;
  font-size: 1.6rem;
  border-radius: 29px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-decoration: none;
}

button.checkoutBtn:focus {
  padding-top: calc(0.5rem - 1px);
  padding-bottom: calc(0.5rem - 1px);
}

button.checkoutBtn:disabled {
  cursor: not-allowed;
  background-color: $theme-terciary-color;
  color: $background-color;
}

button.checkoutBtn > div {
  height: 1.75rem;
  width: 1.75rem;
  font-size: 16px;
}

@media (min-width: 420px) {
  button.checkoutBtn {
    width: 363px;
  }
}
