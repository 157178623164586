@import "../../../assets/styles/_colors.scss";
@import "../../../assets/styles/_sizes.scss";

.container {
  padding-bottom: 35px;
  background-color: $background-color-alt;
}

.header {
  margin-bottom: 55px;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}
