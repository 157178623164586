@import "../../assets/styles/colors";
@import "../../assets/styles/sizes";

.container {
  padding-top: $navbar-height;
}

.courseName {
  font-size: 3rem;
}

.courseDescription,
.testimonials {
  font-size: 1rem;
}

@media (min-width: 768px) {
  .courseDescription,
  .testimonials {
    font-size: 1.08rem;
  }
}

@media (min-width: 992px) {
  .courseDescription,
  .testimonials {
    font-size: 1.2rem;
  }
}
