@import '../../assets/styles/_colors.scss';

.container {
  padding: 0 2rem;
  color: $theme-primary-color-faint;
}

.quoteIcon {
  color: $theme-primary-color-faint-50;
}

.quoteIcon.begin {
  padding-right: 8px;
}

.quoteIcon.end {
  padding-left: 8px;
  margin-right: 16px;
}

.byLineSeparate {
  text-align: right;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}
