@import "../../assets/styles/_colors.scss";
@import "../../assets/styles/_sizes.scss";

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
  overflow-x: scroll;
}

.container:global(.fixed-top) {
  background-color: $background-color;
}

// make the scroll bar disapear without disabling scrolling
.container::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.logoContainer {
  color: $theme-primary-color;
  font-family: "Indie Flower", "Helvetica Neue", sans-serif;
  font-size: 2rem;
}

.logoContainer:hover {
  text-decoration: none;
}

.logoIcon {
  width: 2.4rem;
  vertical-align: text-top;
  display: none;
}

.logoText {
  display: inline-block;
  margin-left: 6px;
  margin-top: 4px;
  font-size: 1.5rem;
  white-space: nowrap;
}

.rightSideContainer {
  display: flex;
  align-items: center;
}

.aboutBtn {
  color: $theme-primary-color;
  font-weight: 500;
  font-size: 1.15rem;
  margin-right: 5px;
  padding: 5px 10px;
  border-radius: 40px;
  background-color: $background-color;
  transition: background-color 0.2s ease;
}

.aboutBtn:hover {
  text-decoration: none;
  background-color: $theme-primary-color-faint-10;
}

.signInButton {
  margin-right: 15px;
  font-weight: 500;
  color: $theme-primary-color;
  border-color: $theme-primary-color;
}
.signInButton:hover {
  background-color: $theme-primary-color-faint-10;
  color: $theme-primary-color;
}

.cartIconContainer {
  margin-left: 7px;
  font-size: 1.75rem;
  margin-right: 5px;
  color: $theme-primary-color;
}

.cartIconNumber {
  font-size: 0.9rem;
  transform: translate(calc(-50% + 0.2rem), calc(-50% - 0.275rem));
  font-weight: 900;
  color: $background-color;
  cursor: default;
}

.userInfoContainer {
  cursor: pointer;
  display: flex;
  align-self: center;
  background-color: $background-color;
  transition: background-color 0.2s ease;
  padding: 5px 12px 5px 20px;
  border-radius: 40px;
}

.userInfoContainer:hover {
  background-color: $theme-primary-color-faint-10;
}

.userName {
  font-size: 1.15rem;
  font-weight: 600;
  margin-right: 10px;
}

.userIcon {
  font-size: 1.6rem;
}

@media (min-width: 453px) {
  .logoIcon {
    display: inline;
  }
  .aboutBtn {
    padding: 5px 20px;
  }
}

@media (max-width: 453px) {
  .removeOnTinyScreen {
    display: none !important;
  }
}

@media (max-width: 325px) {
  .signInButton {
    padding: 0.1rem 0.2rem;
    margin-right: 5px;
  }
}

@media (min-width: 576px) {
  .logoText {
    font-size: 1.75rem;
  }
}

@media (min-width: 768px) {
  .logoText {
    font-size: 2rem;
  }
}

@media (min-width: 992px) {
}
