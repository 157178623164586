@import "../../../assets/styles/_colors.scss";
@import "../../../assets/styles/_sizes.scss";

.container {
  height: 65vh;
}

.tagLine {
  font-size: 52px;
  color: $theme-primary-color;
}

.elevatorPitch {
  margin-top: 20px;
  font-size: 26px;
  color: $theme-primary-color-faint;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}
