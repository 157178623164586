@import "../../../../assets/styles/_colors.scss";
@import "../../../../assets/styles/_sizes.scss";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 0;
  margin-bottom: 15px;
  border-radius: 8px;
}

.iconContainer {
  width: 70px;
  font-size: 50px;
  margin-right: 45px;
}

.factContainer {
  flex: 1;
  margin-bottom: 0;
  font-size: 40px;
}

.number {
  font-weight: 600;
  margin-right: 10px;
}

.unit {
  color: $theme-primary-color-faint;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}
