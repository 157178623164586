@import "../../assets/styles/colors";

.container {
  padding-top: 75px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
}

.courseCard {
  width: 40%;
  display: inline-flex;
  flex-flow: column;
  min-width: 250px;
  max-width: 500px;
  padding: 0 !important;
  height: 50vh;
  min-height: 300px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid $theme-primary-color;
  padding: 1rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.cardsWrapper {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: 100%;
  flex-wrap: wrap;
}

.courseCard:hover {
  box-shadow: 0 0.5rem 1rem rgba(24, 81, 160, 0.15) !important;
}

.courseCardHeader {
  display: flex;
  flex-direction: column;
  margin-left: -1px;
  overflow: hidden;
}
.courseCardImageWrapper {
  border-radius: 3px;
  width: calc(100% + 2px);
  height: 50%;
  overflow: hidden;
}
.courseCardTitleWrapper {
  padding: 1rem;
}
.courseCardBody {
  display: flex;
  flex-direction: column-reverse;
  padding: 0.1rem 0.1rem;
  flex-grow: 1;
  //   height: 40%;
}
.buttonRow {
  display: inline-flex;
  height: fit-content;
  width: 100%;
  flex-direction: row-reverse;
  padding: 1rem;
}
.courseCardImage {
  width: 103%;
  height: 103%;
  overflow: hidden;
  border-radius: 3px;
  object-position: center;
  object-fit: cover;
}
