/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/

.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  // color: #32325d;
  // background-color: white;
  // border: 1px solid transparent;
  border-radius: 29px;

  // box-shadow: 0 1px 3px 0 #e6ebf1;
  // -webkit-transition: box-shadow 150ms ease;
  // transition: box-shadow 150ms ease;
}

.StripeElement--focus,
.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
