// TODO pick a color scheme! https://coolors.co/palettes/trending is a great resource.
$theme-primary-color: #1851a0;
$theme-primary-color-faint: #3766a6;
$theme-primary-color-faint-50: #5982bb;
$theme-primary-color-faint-25: #9bbceb;
$theme-primary-color-faint-10: #d0e3ff;
$theme-primary-color-dark: #2e3238;
$theme-primary-color-dark-50: #4c535c;
$theme-primary-color-dark-25: #737c88;
$theme-secondary-color: #f36811;
$theme-secondary-color-faint: #fc8a44;
$theme-secondary-color-faint-50: #ffbd94;
$theme-secondary-color-faint-25: #ffdac3;
$theme-terciary-color: #0b9d7c;
$theme-terciary-color-faint: #2ca288;
$theme-terciary-color-faint-50: #4fb8a1;
$theme-terciary-color-faint-25: #84dfcb;
$theme-quaternary-color: #f39e11;
$background-color: #fff;
$background-color-alt: #f7f9fc;
$background-color-dark: #e1e4ec;
// $background-color-alt: #f5f5f5;
$theme-secondary-color-0: $background-color;
$theme-secondary-color-5: #fcf1e9; // TODO
$theme-secondary-color-10: #f9e3d3; // TODO
$theme-secondary-color-20: #f7d5bd; // TODO
$theme-secondary-color-30: #f4c7a7; // TODO
$theme-secondary-color-40: #f1b991; // TODO
$theme-secondary-color-50: #eeaa7a; // TODO
$theme-secondary-color-60: #eb9c64; // TODO
$theme-secondary-color-70: #e98e4e; // TODO
$theme-secondary-color-80: #e68038; // TODO
$theme-secondary-color-90: #e37222; // TODO
$failure-color: #bf1d2a;
$success-color: #2abf1d;
$bad-color: #e55934;

// TODO this :export directive is not working. Right now, I've just
// hardcoded the values into constants.js, but I should probably
// figure out why this isn't working anymore.
:export {
    themePrimaryColor: $theme-primary-color;
    themePrimaryColorFaint: $theme-primary-color-faint;
    themePrimaryColorFaint50: $theme-primary-color-faint-50;
    themePrimaryColorFaint25: $theme-primary-color-faint-25;
    themeSecondaryColor: $theme-secondary-color;
    themeSecondaryColorFaint: $theme-secondary-color-faint;
    themeSecondaryColorFaint50: $theme-secondary-color-faint-50;
    themeSecondaryColorFaint25: $theme-secondary-color-faint-25;
    themeSecondaryColor0: $theme-secondary-color-0;
    themeSecondaryColor5: $theme-secondary-color-5;
    themeSecondaryColor10: $theme-secondary-color-10;
    themeSecondaryColor20: $theme-secondary-color-20;
    themeSecondaryColor30: $theme-secondary-color-30;
    themeSecondaryColor40: $theme-secondary-color-40;
    themeSecondaryColor50: $theme-secondary-color-50;
    themeSecondaryColor60: $theme-secondary-color-60;
    themeSecondaryColor70: $theme-secondary-color-70;
    themeSecondaryColor80: $theme-secondary-color-80;
    themeSecondaryColor90: $theme-secondary-color-90;
    themeTerciaryColor: $theme-terciary-color;
    themeTerciaryColorFaint: $theme-terciary-color-faint;
    themeTerciaryColorFaint50: $theme-terciary-color-faint-50;
    themeTerciaryColorFaint25: $theme-terciary-color-faint-25;
    failureColor: $failure-color;
    successColor: $success-color;
    backgroundColor: $background-color;
    backgroundColorAlt: $background-color-alt;
    backgroundColorDark: $background-color-dark;
    badColor: $bad-color;
}
