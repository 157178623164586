@import "./assets/styles/_colors.scss";
@import "./assets/styles/_sizes.scss";

.App {
  color: $theme-primary-color;
}

.border-bottom-light {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); //$theme-primary-color-faint;
}

.inline-link {
  text-decoration: underline;
}

button {
  background-color: unset;
  border: unset;
  padding: unset;
}

.form-control {
  border-bottom-left-radius: 1.75rem;
  border-top-left-radius: 1.75rem;
  border-bottom-right-radius: 1.75rem;
  border-top-right-radius: 1.75rem;
}

// Use this class to make Bootstrap columns equal height
// with their siblings
.equal-height {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: $theme-primary-color;
}

.modal-content {
  background-color: $background-color;
}

// Use this utility class to make sure a page fills the available vertical space
.min-height-page-container {
  min-height: calc(100vh - 168px);
}

@media (min-width: 992px) {
  .min-height-page-container {
    min-height: calc(100vh - 120px);
  }
}

// Add this utility class to a div to create a divider.
.divider {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin: 4px 12px;
}

.cursor-pointer {
  cursor: pointer;
}

@media print {
  .d-print-none {
    display: none;
  }

  .d-print-hidden {
    visibility: hidden;
  }
}

.main-header {
  font-weight: 500;
  margin-bottom: 0;
  font-size: 2rem;
}

@media (min-width: 576px) {
  .main-header {
    font-size: 2.5rem;
  }
}

@media (min-width: 768px) {
  .main-header {
    font-size: 2.75rem;
  }
}

@media (min-width: 992px) {
  .main-header {
    font-size: 3rem;
    text-align: left;
  }
}

//// BEGIN CUSTOM CHECKBOX STYLING ////
.askme-form-check {
  background-color: $theme-secondary-color;
  border-radius: 4px;
  padding: 7px 10px 7px 38px;
}

.askme-form-check ~ .askme-form-check {
  margin-top: 0.5rem;
}

.askme-form-check,
.askme-form-check > * {
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.askme-form-check > input {
  display: none;
}

.askme-form-check > label {
  display: inline-block;
  position: relative;
}

.askme-form-check > label:before {
  color: $background-color;
  background-color: $background-color;
  border: 3px solid $background-color;
  border-radius: 4px;
  width: 17px;
  height: 17px;
  padding-left: 2px;
  font-size: 19px;
  position: absolute;
  top: 5px;
  left: -26px;
  content: " ";
}

.askme-form-check > input:checked + label:before {
  background-color: $theme-primary-color;
}

.askme-form-check > input:checked + label:before {
  content: "\2714";
  background-color: $theme-secondary-color;
  border: 1px solid $theme-secondary-color;
  top: -1px;
  left: -28px;
}
//// END CUSTOM CHECKBOX STYLING ////

.askme-link {
  text-decoration: underline;
  color: inherit;
}

.askme-link:hover {
  color: inherit;
  text-decoration: none;
}

.askme-input-error {
  color: $failure-color;
  font-size: 0.9rem;
  margin-top: 3px;
  text-align: center;
}

.askme-input {
  color: $theme-secondary-color !important;
  font-weight: 600;
}

.askme-input:not(.sections-select):not(.general-select):not(.subject-select),
.askme-input > div.select__control {
  border: 1px solid $theme-secondary-color !important;
  border-top-color: $theme-secondary-color !important;
  border-bottom-color: $theme-secondary-color !important;
  border-left-color: $theme-secondary-color !important;
  border-right-color: $theme-secondary-color !important;
}

.askme-input.thick,
.askme-input.thick > div.select__control {
  border-width: 2px !important;
}

.askme-input-primary {
  border: 1px solid $theme-primary-color !important;
  border-top-color: $theme-primary-color !important;
  border-bottom-color: $theme-primary-color !important;
  border-left-color: $theme-primary-color !important;
  border-right-color: $theme-primary-color !important;
  color: $theme-primary-color !important;
  font-weight: 600;
}

.askme-input-terciary.thick,
.askme-input-terciary.thick > div.select__control {
  border-width: 2px !important;
}

.askme-input-terciary {
  border: 1px solid $theme-terciary-color !important;
  border-top-color: $theme-terciary-color !important;
  border-bottom-color: $theme-terciary-color !important;
  border-left-color: $theme-terciary-color !important;
  border-right-color: $theme-terciary-color !important;
  color: $theme-terciary-color !important;
  font-weight: 600;
}

.askme-input-terciary.thick,
.askme-input-terciary.thick > div.select__control {
  border-width: 2px !important;
}

$default-btn-padding-x: 1.5rem;
$default-btn-padding-y: 0.375rem;
$default-btn-padding: $default-btn-padding-y $default-btn-padding-x;

.askme-btn {
  border: 1px solid $theme-secondary-color;
  background-color: $theme-secondary-color;
  color: $background-color;
  font-weight: 600;
  padding: $default-btn-padding;
}

.askme-btn-inverted {
  border: 1px solid $theme-secondary-color;
  background-color: $background-color;
  color: $theme-secondary-color;
  font-weight: 600;
  padding: $default-btn-padding;
}

.askme-btn-primary {
  border: 1px solid $theme-primary-color;
  background-color: $theme-primary-color;
  color: $background-color;
  font-weight: 600;
  padding: $default-btn-padding;
}

.askme-btn-primary-inverted {
  border: 1px solid $theme-primary-color;
  background-color: $background-color;
  color: $theme-primary-color;
  font-weight: 600;
  padding: $default-btn-padding;
}

.askme-btn-terciary {
  border: 1px solid $theme-terciary-color;
  background-color: $theme-terciary-color;
  color: $background-color;
  font-weight: 600;
  padding: $default-btn-padding;
}

.askme-btn-terciary-inverted {
  border: 1px solid $theme-terciary-color;
  background-color: $background-color;
  color: $theme-terciary-color;
  font-weight: 600;
  padding: $default-btn-padding;
}

.askme-btn-danger {
  border: 1px solid $bad-color;
  background-color: $bad-color;
  color: $background-color;
  font-weight: 600;
  padding: $default-btn-padding;
}

.askme-btn-cancel {
  color: $theme-primary-color;
  cursor: pointer;
}

.askme-btn-cancel.light {
  color: $background-color;
}

.askme-btn:hover {
  color: $theme-secondary-color;
  background-color: $background-color;
}

.askme-btn-inverted:hover {
  color: $background-color;
  background-color: $theme-secondary-color;
}

.askme-btn-primary:hover {
  color: $theme-primary-color;
  background-color: $background-color;
}

.askme-btn-primary-inverted:hover {
  color: $background-color;
  background-color: $theme-primary-color;
}

.askme-btn-terciary:hover {
  color: $theme-terciary-color;
  background-color: $background-color;
}

.askme-btn-cancel:hover {
  color: $theme-primary-color-faint;
}

.askme-btn-cancel.light:hover {
  color: $background-color-dark;
}

.askme-btn-terciary-inverted:hover {
  color: $background-color;
  background-color: $theme-terciary-color;
}

.askme-btn-danger:hover {
  color: $bad-color;
  background-color: $background-color;
}

.askme-btn.thick,
.askme-btn-inverted.thick,
.askme-btn-primary.thick,
.askme-btn-primary-inverted.thick,
.askme-btn-terciary.thick,
.askme-btn-terciary-inverted.thick,
.askme-btn-danger.thick,
.no-focus-btn.thick {
  border-width: 2px;
}

.askme-btn.form-control:focus {
  color: $theme-secondary-color;
}

.askme-btn-inverted.form-control:focus {
  color: $theme-secondary-color;
}

.askme-btn-inverted.form-control:focus:hover {
  color: $background-color;
}

.askme-btn-terciary.form-control:focus {
  color: $theme-terciary-color;
}

.askme-btn-primary.form-control:focus {
  color: $theme-primary-color;
}

.askme-input:focus,
.askme-input-primary:focus,
.askme-input-terciary:focus,
.react-datepicker-wrapper input:focus,
:not(.badge-form-check):not(.badge-form-check-primary) > label:focus,
.askme-btn-cancel:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.badge-form-check:focus,
.badge-form-check-primary:focus,
select.askme-input:focus,
select.askme-input-primary:focus,
select.askme-input-terciary:focus,
select.askme-input > div.select__control:focus,
select.askme-input-primary > div.select__control:focus,
select.askme-input-terciary > div.select__control:focus,
.no-focus-btn:focus,
span:focus,
div:not(.askme-btn-cancel):not(.badge-form-check):focus,
svg:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline-offset: 1px;
  outline-style: auto;
  outline-width: 1px;
  outline-color: $theme-terciary-color;
  border-radius: 0;
}

.askme-btn:not(.askme-btn-cancel):focus,
.askme-btn-inverted:focus,
.askme-btn-primary:focus,
.askme-btn-primary-inverted:focus,
.askme-btn-terciary:focus,
.askme-btn-terciary-inverted:focus,
.askme-btn-danger:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.askme-btn:focus,
.askme-btn-primary:focus,
.askme-btn-terciary:focus {
  border-width: 3px;
  padding-top: calc(#{$default-btn-padding-y} - 2px);
  padding-bottom: calc(#{$default-btn-padding-y} - 2px);
  padding-left: calc(#{$default-btn-padding-x} - 2px);
  padding-right: calc(#{$default-btn-padding-x} - 2px);
}

.askme-btn-inverted:focus,
.askme-btn-primary-inverted:focus,
.askme-btn-terciary-inverted:focus {
  border-width: 3px;
  padding-top: calc(#{$default-btn-padding-y} - 2px);
  padding-bottom: calc(#{$default-btn-padding-y} - 2px);
  padding-left: calc(#{$default-btn-padding-x} - 2px);
  padding-right: calc(#{$default-btn-padding-x} - 2px);
}

.askme-btn-inverted.thick:focus,
.askme-btn-primary-inverted.thick:focus,
.askme-btn-terciary-inverted.thick:focus {
  border-width: 4px;
}

:not(.badge-form-check):not(.badge-form-check-primary) > label:focus,
.askme-btn-cancel:focus {
  text-decoration: underline;
}

.badge-form-check:focus {
  outline-color: $theme-secondary-color;
}

a:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline-style: auto;
  outline-width: 1px;
  outline-color: $theme-terciary-color;
  outline-offset: 0px;
}

.askme-btn:focus {
  border-color: $theme-terciary-color;
}

.askme-btn-inverted:focus {
  border-color: $theme-secondary-color;
}

.askme-btn-primary:focus,
.askme-btn-primary-inverted:focus {
  border-color: $theme-primary-color;
}

.askme-btn-terciary:focus {
  border-color: $theme-primary-color;
}

.askme-btn-terciary-inverted:focus {
  border-color: $theme-terciary-color;
}

.askme-btn-danger:focus {
  border-color: $bad-color;
}

.no-focus-btn:focus {
  border-color: inherit;
}

// Change the default color of input placeholder text.
.askme-input::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: $theme-secondary-color-faint;
}
.askme-input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: $theme-secondary-color-faint;
  opacity: 1;
}
.askme-input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: $theme-secondary-color-faint;
  opacity: 1;
}
.askme-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $theme-secondary-color-faint;
}
.askme-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $theme-secondary-color-faint;
}

.askme-input::placeholder {
  /* Most modern browsers support this now. */
  color: $theme-secondary-color-faint;
}

// Change the color of input placeholder text for .askme-input-primary elements.
.askme-input-primary::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: $theme-primary-color-faint;
}
.askme-input-primary:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: $theme-primary-color-faint;
  opacity: 1;
}
.askme-input-primary::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: $theme-primary-color-faint;
  opacity: 1;
}
.askme-input-primary:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $theme-primary-color-faint;
}
.askme-input-primary::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $theme-primary-color-faint;
}
.askme-input-primary::placeholder {
  /* Most modern browsers support this now. */
  color: $theme-primary-color-faint;
}

// Change the color of input placeholder text for .askme-input-terciary elements.
.askme-input-terciary::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: $theme-terciary-color-faint;
}
.askme-input-terciary:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: $theme-terciary-color-faint;
  opacity: 1;
}
.askme-input-terciary::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: $theme-terciary-color-faint;
  opacity: 1;
}
.askme-input-terciary:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $theme-terciary-color-faint;
}
.askme-input-terciary::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $theme-terciary-color-faint;
}
.askme-input-terciary::placeholder {
  /* Most modern browsers support this now. */
  color: $theme-terciary-color-faint;
}

a.clear-link-styling {
  display: block;
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

.no-select {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

// START overrides for react-select styling
.askme-input div.select__control {
  min-height: 42px;
  box-shadow: none;
}

.sections-select div.select__control {
  cursor: text;
}

.askme-input .select__clear-indicator,
.askme-input .select__multi-value__remove {
  cursor: pointer;
}
// END overrides for react-select styling

// Color utilities //
.color-primary {
  color: $theme-primary-color;
}

.color-secondary {
  color: $theme-secondary-color;
}

.color-terciary {
  color: $theme-terciary-color;
}
// End color utilities //

// START overrides for react-toastify styling
.Toastify__toast {
  font-family: inherit;
  padding-left: 24px;
  padding-right: 18px;
  font-weight: 600;
}
.Toastify__toast-body {
  padding-right: 18px;
}
.Toastify__toast--error {
  background-color: $failure-color;
}

@media (min-width: 480px) {
  .Toastify__toast-container {
    max-width: calc(100% - 30px);
    width: fit-content;
  }

  .Toastify__toast {
    border-radius: 4px;
  }
}
@media (min-width: 768px) {
  .Toastify__toast-container {
    max-width: 80%;
  }
}
@media (min-width: 992px) {
  .Toastify__toast-container {
    max-width: 70%;
  }
}
@media (min-width: 1200px) {
  .Toastify__toast-container {
    max-width: 60%;
  }
}
// END overrides for react-toastify styling
