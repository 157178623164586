@import "../../../assets/styles/_colors.scss";
@import "../../../assets/styles/_sizes.scss";

.container {
  font-size: 1.5rem;
  padding-bottom: 100px;
  background-color: $theme-terciary-color-faint;
  text-align: center;
}

.findMyClassBtn {
  color: $theme-terciary-color-faint;
  border: solid $background-color;
  margin-top: 30px;
  border-radius: 50px;
  width: 300px;
  max-width: 90%;
}

.findMyClassBtn:hover {
  background-color: $theme-terciary-color-faint;
  text-decoration: none;
}

.header {
  margin-bottom: 25px;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}
