@import '../../assets/styles/_colors.scss';
@import '../../assets/styles/_sizes.scss';

.container {
    color: $theme-primary-color;
    padding-top: 25px;
    padding-bottom: 50px;
}

.hugeIcon {
    font-size: 120px;
}
