@import "../../assets/styles/colors";
@import "../../assets/styles/sizes";

.pageTitle {
  font-size: 3rem;
}

.countdownTimer {
  color: $theme-secondary-color;
  font-weight: 400;
  font-size: 1.5rem;
  width: 180px;
}

.countdownTimer > span {
  position: relative;
  top: -5px;
}

.checkoutContainer {
  padding-top: $navbar-height;
  padding-bottom: 60px;
}

.checkoutBtn {
  width: 363px;
  font-size: 1.6rem;
  border-radius: 29px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-decoration: none;
}

.totalPrice {
  font-size: 1.6rem;
}

.expirationModal div:global(.modal-content) {
  border: none;
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
  border-radius: 8px;
}

.expirationModal div:global(.modal-footer) {
  border-top: none;
}

.expirationModalMessage {
  color: $theme-primary-color;
  padding-bottom: 1.5rem;
  font-size: 1.75rem;
}

.expirationModalButton {
  cursor: pointer;
  border-radius: 29px;
}
