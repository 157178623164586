@import "../../assets/styles/_colors.scss";
@import "../../assets/styles/_sizes.scss";

footer {
    background-color: $theme-primary-color;
    color: $background-color;
}

.contactUs > svg {
    margin-right: 10px;
}

.contactUs {
    color: white;
    text-decoration: none;
}

a.contactUs:hover {
    color: $theme-secondary-color;
    text-decoration: underline;
}
