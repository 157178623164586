@import "../../../assets/styles/colors";

.copyButton {
  color: $theme-secondary-color;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 4px;
  padding: 4px 12px;
  cursor: pointer;
}

.copyButton:hover {
  color: $background-color;
  background-color: $theme-secondary-color;
}

.copyButton:disabled {
  color: $theme-secondary-color;
  background-color: $background-color;
  cursor: default;
}
