@import "../../../../assets/styles/colors";

.button {
  border-radius: 19px;
}

.button:focus:hover {
  color: $background-color;
  background-color: $theme-secondary-color;
}
