@import "../../../assets/styles/_colors.scss";

.cardContainer {
  padding-left: 30px;
  padding-right: 30px;
  cursor: pointer;
}

.card {
  border-radius: 4px;
}

.card.unpublished {
  border-left: 20px solid $theme-secondary-color;
}

.card:hover {
  box-shadow: 0 0.5rem 1rem rgba(24, 81, 160, 0.15) !important;
}

.courseName {
  font-size: 1.25rem;
  font-weight: 500;
  display: inline-block;
}

.courseDatesContainer {
  display: inline-block;
  padding: 2px 12px;
  color: $theme-terciary-color;
  border: 1px solid $theme-terciary-color;
  border-radius: 4px;
}

.testimonials {
  font-size: 0.9rem;
}

.location,
.sessionPrice {
  font-size: 1.25rem;
  font-weight: 500;
}
