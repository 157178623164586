@import "../../assets/styles/colors";

.container {
  padding-top: 75px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
}

.main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}

.upcomingSessionCard {
  display: inline-block;
  padding: 1rem;
  border: 1px solid $theme-primary-color;
  max-width: 32vw;
  min-width: 300px;
}

.futureScheduleCard {
  padding: 1rem;
  padding-bottom: 0;
  border: 1px solid $theme-primary-color;
  // width: 45%;
  min-width: 300px;
  max-width: 32vw;
  max-height: 50vh;
  height: 100%;
  overflow-y: scroll;
}

.upcomingSessionWrapper {
  display: inline-block;
}

.futureScheduleWrapper {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.imageBadge {
  width: 100%;
}

.imageBadgeWrapper {
  position: absolute;
  z-index: -2;
  display: block;
  overflow: hidden;
  // width: 25%;
  height: 100%;
  min-width: 150px;
  border-radius: 5px;
  border: 1px solid $theme-primary-color;
}

.headerRow {
  overflow: hidden;
  display: block;
  padding: 0.25rem;
}

.backplate {
  background-color: white;
}

.strong {
  font-weight: bold;
}

.weak {
  color: $theme-primary-color-faint-25;
}
