@import "../../assets/styles/_colors.scss";
@import "../../assets/styles/_sizes.scss";

#faq-container {
  padding-top: 65px;
  padding-bottom: 80px;
}

.questionDropCap {
  color: $theme-secondary-color;
}

.answerCol {
  padding-left: 3rem;
}

@media (max-width: 425px) {
  .answerCol {
    padding-left: 1rem;
  }
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}
