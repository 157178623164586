@import "../../../assets/styles/colors";

.cardContainer {
  padding-left: 30px;
  padding-right: 30px;
}

.card {
  border-radius: 4px;
}

.addToCartBtn {
  border-radius: 19px;
}

.addToCartBtn:focus:hover {
  color: $background-color;
  background-color: $theme-secondary-color;
}

.dateSelectorLabel {
  font-size: 1.2rem;
}

.dateSelector {
  color: $theme-secondary-color;
  font-weight: 600;
}

.compactSessionName {
  font-size: 1.5rem;
}

.compactSessionNumber {
  font-size: 1.1rem;
}

.compactInfo {
  white-space: nowrap;
  font-size: 1.12rem;
}

.fillerDiv {
  height: 100%;
  height: -moz-available; /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  height: stretch;
}
