@import "../../assets/styles/_colors.scss";
@import "../../assets/styles/_sizes.scss";

.sectionContainer {
  padding-top: 60px;
}

.sectionHeader {
  text-align: center;
  font-size: 2rem;
  color: $theme-primary-color-dark;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .sectionHeader {
    font-size: 2.5rem;
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}
