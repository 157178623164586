@import "../../../assets//styles/colors";

.priceSubtotal,
.priceBundleSavings,
.priceTotal {
  padding-left: 25px;
  padding-right: 25px;
}

.priceTotal {
  font-size: 1.1rem;
}
