@import "../../assets/styles/_sizes.scss";
@import "../../assets/styles/_colors.scss";

.container {
  padding-top: $navbar-height;
}

.headerText,
.pastCoursesHeaderText {
  margin-bottom: 0;
}

.pastCoursesHeaderText {
  font-size: 26px;
  margin-top: 15px;
  text-align: center;
}

.subjectBadgesContainer {
  overflow-x: scroll;
}

.subjectBadgesContainer::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.noCoursesMessage {
  margin-top: 20px;
  margin-bottom: 25px;
  font-size: 18px;
  text-align: center;
}

.noCoursesMessage > p {
  margin-bottom: 5px;
}

@media (min-width: 576px) {
  .header {
    display: flex;
    align-items: center;
  }

  .pastCoursesHeaderText {
    text-align: left;
  }

  .headerText {
    margin-right: 15px;
  }

  .pastCoursesHeaderText {
    font-size: 28px;
  }

  .noCoursesMessage {
    margin-top: 25px;
    font-size: 20px;
  }
}

@media (min-width: 768px) {
  .pastCoursesHeaderText {
    font-size: 32px;
  }

  .noCoursesMessage {
    margin-top: 30px;
    font-size: 26px;
  }
}

@media (min-width: 992px) {
  .pastCoursesHeaderText {
    font-size: 38px;
    margin-top: 25px;
  }
  .noCoursesMessage {
    font-size: 28px;
  }
}
