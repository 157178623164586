@import "../../../assets/styles/colors";

.courseName {
  font-weight: 600;
  font-size: 1.25rem;
  margin-bottom: 0;
}

.courseSubscriptionPrice {
  margin-right: 25px;
}

.session {
  padding-left: 25px;
  padding-right: 25px;
}

.event {
  color: $theme-secondary-color;
  font-size: 0.95rem;
  padding-left: 20px;
  padding-right: 20px;
}
