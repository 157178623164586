@import '../../../../assets/styles/_colors.scss';
@import '../../../../assets/styles/_sizes.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: $theme-primary-color;
  border-radius: 8px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin: 0px 15px 45px;
  background-color: $background-color;
}

.container:hover {
  color: $theme-primary-color;
  text-decoration: none;
}

.container:hover .icon,
.container:hover .subjectName {
  color: $theme-secondary-color;
}

.icon {
  display: inline-block;
  font-size: 55px;
  margin-bottom: 20px;
}

.subjectName {
  display: inline-block;
  font-size: 16px;
}

@media (min-width: 576px) {
  .container {
    padding-top: 45px;
    padding-bottom: 45px;
    margin: 0px 20px 45px;
  }

  .subjectName {
    font-size: 32px;
  }
}

@media (min-width: 768px) {
  .container {
    padding-top: 35px;
    padding-bottom: 35px;
    margin: 0px 0px 45px;
  }
}

@media (min-width: 992px) {
  .container {
    padding-top: 50px;
    padding-bottom: 50px;
    margin: 0px 15px 45px;
  }
}
